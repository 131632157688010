var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-radius-10 text-center d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-6' : ''},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",class:{
        'pr-2': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column align-center fill-width algo-grey darken-1 ticket-stats-padding border-radius-10",class:{
          'px-4': _vm.$vuetify.breakpoint.smAndDown,
          'px-6': !_vm.$vuetify.breakpoint.smAndDown,
        }},[_c('div',{staticClass:"d-flex flex-column fill-height fill-width align-center"},[_c('div',{staticClass:"whitelist-title-project primary--text"},[_vm._v("Staking pool")]),_c('div',{staticClass:"algo-grey algo-grey darken-2 pa-7 fill-width border-radius-5 mt-6 text-start collection-frame-width"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"staking-pool-title"},[_vm._v("My Staked LCH")]),_c('div',{staticClass:"staking-value-primary",class:{
                  'ml-3': _vm.$vuetify.breakpoint.xl,
                  ' ml-2': !_vm.$vuetify.breakpoint.xl,
                }},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.vm.tierAmount,2, 0))+" LCH ")])]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"staking-pool-title"},[_vm._v("My Tier")]),_c('div',{staticClass:"ml-2 staking-value-primary",class:{
                  'ml-3': _vm.$vuetify.breakpoint.xl,
                  'ml-2': !_vm.$vuetify.breakpoint.xl,
                }},[_vm._v(" "+_vm._s(_vm.vm.tierIndex)+" ")])])]),_c('v-img',{staticClass:"align-self-center my-10",class:{
              'mt-8 mb-4': !_vm.$vuetify.breakpoint.lgAndUp,
              'mt-7 mb-5': _vm.$vuetify.breakpoint.lgAndUp,
            },style:(_vm.$vuetify.breakpoint.xl ? 'max-width:83px' : 'max-width:64px'),attrs:{"src":require("@/assets/icons/staking-ticket-icon.svg"),"contain":""}}),_c('div',{staticClass:"staking-pool-title"},[_vm._v("You have")]),(_vm.vm.goldenTicketForceShow > 0)?_c('div',{staticClass:"border-radius-5 fill-width whitelist-bold-label mt-2 collection-frame-width",class:{
              'd-flex justify-space-between align-center': !_vm.$vuetify.breakpoint.smAnDown,
              'd-flex flex-column': _vm.$vuetify.breakpoint.sm,
            }},[_c('div',{staticClass:"algo-grey darken-2 d-flex align-center justify-center ticket-number-stats collection-frame-height"},[_vm._v(" "+_vm._s(_vm.vm.totalTicketsForceShow)+" Ticket(s) ")]),_c('v-img',{class:_vm.$vuetify.breakpoint.sm ? 'my-2' : 'mx-2',attrs:{"src":require("@/assets/icons/plus-dark-icon.svg"),"max-height":"14px","contain":""}}),_c('div',{staticClass:"d-flex justify-space-between align-center golden-ticket collection-frame-height"},[_c('v-img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/golden-star-icon.svg"),"max-width":"13","contain":""}}),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.vm.goldenTicketForceShow)+" Golden Ticket"+_vm._s(_vm.vm.goldenTicketForceShow > 1 ? 's' : ''))]),_c('v-img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/golden-star-icon.svg"),"max-width":"13","contain":""}})],1)],1):_c('div',{staticClass:"border-radius-5 algo-grey darken-2 pa-3 fill-width whitelist-bold-label mt-2 collection-frame-width ticket-number-stats",class:{
              'height-50 py-6': !_vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(" "+_vm._s(_vm.vm.totalTicketsForceShow)+" Tickets ")]),_c('v-btn',{staticClass:"full-width d-flex flex-start text-none mt-4 collection-frame-width",class:{
              'button-60  border-radius-5': _vm.$vuetify.breakpoint.mdAndUp && _vm.$vuetify.breakpoint.lgAndDown,
              'button-60': _vm.$vuetify.breakpoint.xl,
              'button-50  ': !_vm.$vuetify.breakpoint.mdAndUp,
              'border-radius-5': _vm.$vuetify.breakpoint.mdAndUp,
              'algo-btn btnA ': _vm.vm.canApplyStakingTickets,
              'algo-btn btnA--disabled ': !_vm.vm.canApplyStakingTickets,
            },attrs:{"loading":_vm.vm.loadingApplyTicket || _vm.vm.fetchingStaking},on:{"click":function($event){return _vm.vm.applyStakingTickets()}}},[_vm._v("Apply ")]),_c('div',{staticClass:"fill-width algo-grey mt-9 mb-5 collection-frame-width",staticStyle:{"height":"1px"}}),_c('div',{staticClass:"staking-note"},[_vm._v("Start Staking LCH to gain tickets in the Staking Pool")]),_c('v-btn',{staticClass:"algo-btn btnC full-width d-flex flex-start text-none mt-5 collection-frame-width",class:{
              'button-60 border-radius-5': _vm.$vuetify.breakpoint.mdAndUp && _vm.$vuetify.breakpoint.lgAndDown,
              'button-60': _vm.$vuetify.breakpoint.xl,
              'button-50': !_vm.$vuetify.breakpoint.mdAndUp,
            },on:{"click":function($event){return _vm.startStaking()}}},[_vm._v("Start Staking")])],1)])]),_c('v-col',{staticClass:"pa-0",class:{
        'pl-2': _vm.$vuetify.breakpoint.lgAndUp,
        'mt-6': !_vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column align-center fill-width fill-height algo-grey darken-1 ticket-stats-padding border-radius-10",class:{
          'px-4': _vm.$vuetify.breakpoint.smAndDown,
          'px-6': !_vm.$vuetify.breakpoint.smAndDown,
        }},[_c('div',{staticClass:"d-flex flex-column align-center fill-height fill-width"},[_c('div',{staticClass:"whitelist-title-project primary--text"},[_vm._v("Social pool")]),_c('div',{staticClass:"mt-6 social-note"},[_vm._v("You can get Social Winning Tickets by performing Social Tasks on Gleam.")]),_c('v-img',{staticClass:"align-self-center mt-11",class:{
              'mb-16': !_vm.$vuetify.breakpoint.lgAndUp,
            },style:(_vm.$vuetify.breakpoint.xl ? 'max-width:71px' : 'max-width:54px'),attrs:{"src":require("@/assets/icons/social-gleam-icon.svg"),"contain":""}}),_c('v-spacer',{staticClass:"flex-fill"}),_c('v-btn',{staticClass:"algo-btn btnA full-width d-flex flex-start text-none mt-4 collection-frame-width",class:{
              'button-60  border-radius-5': _vm.$vuetify.breakpoint.mdAndUp && _vm.$vuetify.breakpoint.lgAndDown,
              'button-60': _vm.$vuetify.breakpoint.xl,
              'button-50': !_vm.$vuetify.breakpoint.mdAndUp,
              'border-radius-5': _vm.$vuetify.breakpoint.mdAndUp,
            },on:{"click":_vm.goGleam}},[_vm._v("Go Gleam")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }