















































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {},
})
export default class TicketCollection extends Vue {
  walletStore = walletStore
  @Inject() vm!: IdoApplyViewModel
  startStaking() {
    this.$router.push('/staking')
  }
  goGleam() {
    window.open('https://gleam.io/', '_blank')
  }
}
